







































import {
  TableColumn,
  TableComponent,
} from '@dnb-open-banking/vue-table-component';
import { TagDto } from '@portals/shared/admin/TagDto';
import { format } from 'date-fns';
import Vue from 'vue';

import DnbIcon from '@/components/DnbIcon.vue';
import Loader from '@/components/Loader.vue';
import { fetchTags } from '@/service/tagService';

type Data = {
  tags: TagDto[];
  loading: boolean;
};

export default Vue.extend({
  name: 'tags',
  components: {
    Loader,
    TableComponent,
    TableColumn,
    DnbIcon,
  },
  data(): Data {
    return {
      tags: [],
      loading: false,
    };
  },
  mounted() {
    this.fetchTags();
  },
  methods: {
    dateFormatter(date: Date) {
      if (!date) return 'N/A';
      return format(date, 'DD.MM.YYYY');
    },

    async fetchTags() {
      this.loading = true;
      this.tags = await fetchTags();
      this.loading = false;
    },
    onRowClick(row: { data: TagDto }) {
      this.$router.push(`/tags/tag/${row.data.id}`);
    },
  },
});
